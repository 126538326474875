<template>
  <el-dialog
    title="数据导出"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @close="isStop = true"
  >
    <el-progress
      :text-inside="true"
      :stroke-width="26"
      :percentage="percentage"
    ></el-progress>

    <div class="msg_box">
      <div class="msg">* 数据导出中, 请耐心等待!</div>
      <div class="msg">* 请勿关闭弹窗, 关闭弹窗将会终止导出!</div>
      <div class="msg">* 导出成功, 请自行查看下载文件!</div>
    </div>
  </el-dialog>
</template>

<script>
import XLSX from 'xlsx'
import DateFmt from '@/utils/DateFmt.js'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 下载进度
      percentage: 30,
      // 导出的名字
      name: '',
      // 导出数据的函数
      fn: '',
      // 参数
      params: '',
      // 需要下载的键名
      keyMap: {},
      // 键映射
      dataMap: [],
      // 总数映射
      totalMap: [],
      // 值格式化
      valueFmt: [],
      // 下载的文件
      downloadData: [],
      // 分页数据
      page_id: 0,
      page_num: 500,
      total: 0,
      // 是否停止导出
      isStop: false
    }
  },
  methods: {
    export(option) {
      this.name = option.name || '数据导出'
      this.fn = option.fn
      const params = option.params
      delete params.page_id
      delete params.page_num
      this.params = option.params
      this.keyMap = option.keyMap
      this.dataMap = option.dataMap
      this.totalMap = option.totalMap
      this.valueFmt = option.valueFmt
      this.dialogVisible = true
      this.isStop = false
      this.getData()
    },

    // 获取数据
    async getData() {
      const params = Object.assign(this.params, {
        page_id: this.page_id,
        page_num: this.page_num
      })
      const { ret, data, msg } = await this.fn(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      // 读取总数
      this.total = this.totalMap.reduce((acc, cur, index, arr) => {
        return acc[cur]
      }, data)
      // 处理需要合并的数据
      const contactData = this.dataMap.reduce((acc, cur, index, arr) => {
        return acc[cur]
      }, data)

      // 缓存在内存中
      this.downloadData = this.downloadData.concat(contactData)

      // 更新进度
      // 下载的个数
      const downloadCount = (this.page_id + 1) * this.page_num
      if (downloadCount >= this.total) {
        // 下载已完成
        this.percentage = 100
        this.handleData()
        return
      } else {
        // 没有下载完成
        this.percentage = Number(
          ((downloadCount / this.total) * 100).toFixed(4)
        )
      }
      if (this.downloadData.length >= 20000) {
        this.handleData()
      }

      // 点击了叉号或者 esc 关闭了弹窗, 自动停止导出
      if (this.isStop) {
        this.handleData()
        return
      }
      this.page_id++
      this.getData()
    },

    // 处理数据
    handleData() {
      // 下载的键名集合
      const keyArray = Object.keys(this.keyMap)
      // excel表头
      const excelTitle = []
      keyArray.map((i) => {
        excelTitle.push(this.keyMap[i])
      })
      // 下载的内容
      const excelContent = []
      this.downloadData.map((i) => {
        const temp = []
        keyArray.map((j) => {
          if (this.valueFmt[j]) {
            // 需要格式化
            temp.push(this.valueFmt[j](i[j]))
          } else {
            // 不需要格式化
            temp.push(i[j])
          }
        })
        excelContent.push(temp)
      })
      this.download(excelTitle, excelContent)
    },

    download(headerReplace, content) {
      // 展示名称
      // 数据源
      const sheet = [headerReplace, ...content]

      // 创建一个sheet表格   使用json_to_sheet, 数据格式比较为  数组包对象, 不然会报错
      const worksheet = XLSX.utils.aoa_to_sheet(sheet, {
        skipHeader: true
      })

      // 简单理解为在内存中 创建一个xlsx 文件
      const newWorkBook = XLSX.utils.book_new()
      // 把worksheet添加到workbook中
      XLSX.utils.book_append_sheet(newWorkBook, worksheet, 'SheetJS')
      // 冬天生成名字
      const name = this.name + new DateFmt().format('yyyy/MM/dd hh:mm:ss')
      // 写入文件, CHROME浏览器会直接下载, 后面的是文件名称和后缀
      XLSX.writeFile(newWorkBook, `${name}.xlsx`)
    }
  }
}
</script>

<style lang='scss' scoped>
.msg_box {
  padding: 24px 0 12px 16px;

  .msg {
    margin: 12px 0;
    color: red;
  }
}
</style>
