class DateFmt {
  // 如果传入时间参数 就是和传入的时间参数进行比较
  constructor(prop = new Date()) {
    const timeStr = prop.toString().replace(/-/g, '/')
    this.date = timeStr
  }

  // 格式化日期
  format(str) {
    try {
      const date = new Date(this.date)
      const yyyy = date.getFullYear()
      const MM = (date.getMonth() + 1).toString().padStart(2, '0')
      const dd = date
        .getDate()
        .toString()
        .padStart(2, '0')
      const hh = date
        .getHours()
        .toString()
        .padStart(2, '0')
      const mm = date
        .getMinutes()
        .toString()
        .padStart(2, '0')
      const ss = date
        .getSeconds()
        .toString()
        .padStart(2, '0')
      if (str) {
        return str
          .replace('yyyy', yyyy)
          .replace('MM', MM)
          .replace('dd', dd)
          .replace('hh', hh)
          .replace('mm', mm)
          .replace('ss', ss)
      }
      return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`
    } catch (err) {
      return '--:--:--'
    }
  }

  // 获取时间差异
  getDiffTime(day = 0, str = 'yyyy-MM-dd') {
    try {
      const diff = new Date(this.date).getTime() + day * 24 * 60 * 60 * 1000
      const res = new Date(diff)
      const yyyy = res.getFullYear()
      const MM = (res.getMonth() + 1 + '').padStart(2, '0')
      const dd = (res.getDate() + '').padStart(2, '0')
      const hh = (res.getHours() + '').padStart(2, '0')
      const mm = (res.getMinutes() + '').padStart(2, '0')
      const ss = (res.getSeconds() + '').padStart(2, '0')
      return str
        .replace('yyyy', yyyy)
        .replace('MM', MM)
        .replace('dd', dd)
        .replace('hh', hh)
        .replace('mm', mm)
        .replace('ss', ss)
    } catch (Exception) {
      return '--:--:--'
    }
  }

  // 时间排序
  timeSort(arr) {
    // hh:mm:ss
    const list = [...new Set(arr)]
    // 时间排序
    const listStemp = []
    list.map(i => {
      const h = i.substring(0, 2) / 1
      const m = i.substring(3, 5) / 1
      const s = i.substring(6, 8) / 1
      listStemp.push(h * 60 * 60 + m * 60 + s)
    })
    listStemp.sort()
    const returnList = []
    listStemp.map(j => {
      const hh = (parseInt(j / 3600) + '').padStart(2, '0')
      const mm = (parseInt((j / 60) % 60) + '').padStart(2, '0')
      const ss = ((j % 60) + '').padStart(2, '0')
      returnList.push(`${hh}:${mm}:${ss}`)
    })
    return returnList
  }
}

export default DateFmt
